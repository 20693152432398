@import "../../scss/globals";

img.logo {width: 25%; height: 25%;}
.navbar{
	margin-bottom: 0;
	border-radius: 0;
}
nav{
	&.navbar {
		position: fixed;
		width: 100%;
		z-index: 999;
		background-color: transparent;
		border-color: $darkBlue;
		top: 0;
	}
	.navbar-header{
		.navbar-brand{
			img{
				margin-top: -5px;
			}
		}
	}
	.navbar-nav{
		li{
			a{
				color: $orange;
				&:hover{
					color:$darkOrange;
				};
			}
		}
		
	}
	h1{
		display: inline;
		font-family: 'Lora', sans-serif;
		a{
			color: #000!important;
			font-size: 2rem;
		}
	}
}
.header{
	background-color: $mainBlue;
	margin-bottom: 0;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	h1{
		text-align: center;
		color: #FFF;
	}
}
/* Position and sizing of burger button */
.bm-burger-button {
	position: fixed;
	width: 36px;
	height: 30px;
	right: 36px;
	top: 10px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
	height: 24px;
	width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
	background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	position: fixed;
	height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
	background: #373a47;
	padding: 2.5em 1.5em 0;
	font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
	a.menu-item {
		color: #FFF;
	}
	a:visited{
		color: #FFF;
	}
  }
  
  /* Individual item */
  .bm-item {
	display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
  }