.about {
    .container.introCopy {
        padding-bottom: 150px;
    }
    .header {
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
    }
}
